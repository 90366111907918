import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Form, Input, Select, Row, Col, Card, notification, Skeleton, Badge, DatePicker, InputNumber, Tag, Switch,
} from 'antd';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import systemService from '../../services/system';
import Header from '../../components/header';
import DefaultLayout from './../../components/layout/DefaultLayout';
import './index.css';
const { Option } = Select;

const Settings = () => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedExtensions, setSelectedExtensions] = useState([]);
  const { currentUser } = useSelector((state) => state.user);

  const [data, setData] = useState(null);

  const getSystemSettings = async () => {
    setLoading(true);
    try {
      const response = await systemService.getSystemSettings();
      const systemSettings = response[0];
  
      let allowedExtensionsArray = [];
      if (systemSettings.allowed_extensions) {
        try {
          allowedExtensionsArray = JSON.parse(systemSettings.allowed_extensions);
        } catch (jsonError) {
          console.log(jsonError);
          allowedExtensionsArray = [];
        }
      }
  
      setData(systemSettings);
      setLoading(false);
  
      form.setFieldsValue({
        email_transport: systemSettings.email_transport,
        email_host: systemSettings.email_host,
        email_username: systemSettings.email_username,
        email_password: systemSettings.email_password,
        email_port: systemSettings.email_port,
        email_encryption: systemSettings.email_encryption,
        max_upload_size: systemSettings.max_upload_size,
        allowed_extensions: allowedExtensionsArray,
      });
      
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: error.response,
      });
    }
  };

  useEffect(() => {
    getSystemSettings();
  }, []);

  const onFinish = async (values) => {
    //console.log(values)
    setBtnLoading(true);
    try {
      const response = await systemService.updateSystemSettings(values);
      if (response.success) {
        notification.success({
          message: 'Settings has been Updated',
          description: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
      });
    } finally {
      setBtnLoading(false);
    }
  };


  //   const addField = (fieldBox) => {
  //     console.log('yes')
  //     console.log(fieldBox)
  //     if(fieldBox === "transaction_limits"){
  //       const newField = {
  //         id: fields.length,
  //         date_range: '',
  //         transactions: '',
  //       };
  //       setFields([...fields, newField]);
  //     }
  //     if(fieldBox === "storage_limits"){
  //       const newField2 = {
  //         id: storageFields.length,
  //         date_range: '',
  //         mbs: '',
  //       };
  //       setStorageFields([...storageFields, newField2]);
  //     }
  //     if(fieldBox === "user_limits"){
  //       const newField3 = {
  //         id: userFields.length,
  //         date_range: '',
  //         users: '',
  //         users_login: '',
  //       };
  //       setUserFields([...userFields, newField3]);
  //     }
    
  // };

  // const removeField = (id, fieldBox) => {

  //   if(fieldBox === "transaction_limits"){
  //     const updatedFields = fields.filter((field) => field.id !== id);
  //     setFields(updatedFields);
  //   }
  //   if(fieldBox === "storage_limits"){
  //     const updatedFields2 = storageFields.filter((field) => field.id !== id);
  //     setStorageFields(updatedFields2);
  //   }
  //   if(fieldBox === "user_limits"){
  //     const updatedFields3 = userFields.filter((field) => field.id !== id);
  //     setUserFields(updatedFields3);
  //   }
  // };

  const [fields, setFields] = useState([]);
  const [settingsEnabled, setSettingsEnabled] = useState(false);
  const [isAllowedIp, setIsAllowedIp] = useState(true);

  const addField = () => {
    setFields([...fields, { id: Date.now(), range: '', reason: '' }]);
  };

  const removeField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const handleToggle = (checked) => {
    setSettingsEnabled(checked);
  };

  const handleIpTypeToggle = (checked) => {
    setIsAllowedIp(checked);
  };
  return (
    <DefaultLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header
            icon={<UserOutlined />}
            title="IP Address Restrictions"
            right={<></>}
          />
        </Col>
      </Row>
      {/*<Card>*/}
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          scrollToFirstError
          className="mt-20"
        >

{/*        <Card title="Workflow" style={{marginRight: '30px', marginLeft: '30px', marginBottom: '30px'}}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              {fields.map((field, index) => (
              
                  <Row
                    gutter={[12, 12]}
                    key={field.id}
                    style={{ display: 'flex', marginBottom: 34 }}
                  >
                    <Col span={6}>
                      <Form.Item
                        name={['transaction_limits', field.id, 'date_range']}
                        label="Date Range"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a date range',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker size="large" style={{ width: '80%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item
                        name={['transaction_limits', field.id, 'transactions']}
                        label="Number of Transactions"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the number of transactions',
                          },
                        ]}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                     <Col span={3}>
                    <Form.Item
                      name={['transaction_limits', field.id, 'package']}
                      label="Package"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a package',
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        style={{ width: '100%' }}
                        placeholder="Please select a package"
                      >
                        <Select.Option value="package one">Package One</Select.Option>
                        <Select.Option value="package two">Package Two</Select.Option>
                        <Select.Option value="package three">Package Three</Select.Option>
                      </Select>
                    </Form.Item>
                    </Col>
                     <Col span={4} >
                    <Tag style={{ marginTop: '25px', marginLeft: '25px' }}> <b>22</b> out of <b>50</b> </Tag>
                    <Tag style={{ marginTop: '25px' }}> <b>Transaction ID:</b> - </Tag>
                    </Col>

                    <Col span={2}>
                      {field.id === 0 ? null : (
                        <Button
                          style={{ marginTop: '27px' }}
                          size="large"
                          onClick={() => removeField(field.id,'transaction_limits')}
                        >
                          -
                        </Button>
                      )}
                    </Col>
                  </Row>
              ))}
              <Form.Item
                className=""
                style={{ marginRight: '10px' }}
              >
                <Button
                  type="dashed"
                  onClick={() => addField('transaction_limits')}
                  icon={<PlusOutlined />}
                >
                  Add More
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>*/}

           <Card title="IP Restriction Settings" style={{ margin: '30px' }}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row gutter={[12, 12]} style={{ marginBottom: '20px' }}>
            <Col span={2}  style={{ marginBottom: '20px' }}>
              <Switch
                checked={settingsEnabled}
                onChange={handleToggle}
                checkedChildren="On"
                unCheckedChildren="Off"
              />
            </Col>
            <Col span={4}>
              <Switch
                checked={isAllowedIp}
                onChange={handleIpTypeToggle}
                checkedChildren="Allowed"
                unCheckedChildren="Blocked"
              />
            </Col>
          </Row>
          {settingsEnabled &&
            fields.map((field, index) => (
              <Row gutter={[12, 12]} key={field.id} style={{ display: 'flex', marginBottom: 20 }}>
                <Col span={6}>
                  <Form.Item
                    label="IP Range"
                    name={['ip_restrictions', field.id, 'range']}
                    rules={[{ required: true, message: 'Please enter an IP range' }]}
                  >
                    <Input
                      placeholder="e.g., 192.168.0.1-192.168.0.255"
                      size="large"
                      value={field.range}
                      onChange={(e) => {
                        const updatedFields = [...fields];
                        updatedFields[index].range = e.target.value;
                        setFields(updatedFields);
                      }}
                    />
                  </Form.Item>
                </Col>
                
                <Col span={6}>
                  <Form.Item
                    label="Reason"
                    name={['ip_restrictions', field.id, 'reason']}
                    rules={[{ required: true, message: 'Please enter a reason' }]}
                  >
                    <Input
                      placeholder="Reason for allowing/blocking"
                      size="large"
                      value={field.reason}
                      onChange={(e) => {
                        const updatedFields = [...fields];
                        updatedFields[index].reason = e.target.value;
                        setFields(updatedFields);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    style={{ marginTop: '30px' }}
                    size="large"
                    onClick={() => removeField(field.id)}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}
          {settingsEnabled && (
            <Form.Item>
              <Button type="dashed" onClick={addField} icon={<PlusOutlined />}>
                Add IP Range
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Card>

<Row>
  <Col lg={24} md={24} sm={24} xs={24}>
        <div className="text-right" style={{ marginRight: '30px' }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save Settings
            </Button>
          </Form.Item>
        </div>
      </Col>
  </Row>

        </Form>
      {/*</Card>*/}
    </DefaultLayout>
  );
};

export default Settings;
